/**
 * FIELDS
 */

const PROJECT_FIELDS = `
  id
  name
  type
  siren
  intraCommunityTvaNumber
  capital
  address
  city
  postalCode
  logo
  iban
  bic
  parameters {
    primaryColor
    secondaryColor
  }
`
const CLIENT_FIEDS = `
  id
  name
  postalCode
  city
  address
`

const INVOICE_FIELDS = `
  id
  number
  isPaid
  dates {
    end
    print
  }
  prices {
    total
    totalWithoutTaxes
    taxes
    taxesRatio
  }
  lines {
    title
    count
    pricePerUnit
    total
  }
  project {
    ${PROJECT_FIELDS}
   }
   client {
     ${CLIENT_FIEDS}
   }
`

/**
 * QUERY
 */

export const projects = () => `
  projects {
    ${PROJECT_FIELDS}
  }
`

export const clients = () => `
  clients {
    ${CLIENT_FIEDS}
  }
`

export const invoices = id => `
  invoices(id: "${id}") {
    ${INVOICE_FIELDS}
  }
`

export const addInvoice = () => `
  AddInvoice ($invoice: InputInvoice!) {
    addInvoice(input: $invoice) {
      ${INVOICE_FIELDS}
    }
  }
`

export const replaceInvoice = () => `
replaceInvoice ($invoice: InputReplaceInvoice!) {
  replaceInvoice(input: $invoice) {
      ${INVOICE_FIELDS}
    }
  }
`
